import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout, EmailBanner, TableRow } from '../components'
import { HTMLContent, BlogPostTemplate } from '../../../shared'

import DogIcon from '../img/dog-icon.png'
import { media } from '../../../shared/utils/style-utils'

const Dog = styled.img`
  height: auto;
  position: absolute;
  margin: 0;
  ${media.small`
    width: 70px;
    right: 2%;
  `}
  ${media.medium`
    width: 85px;
    bottom: -1px;
    right: 3%;
  `}
`
const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data
  const { html, frontmatter } = post

  return (
    <Layout>
      <BlogPostTemplate
        contentComponent={HTMLContent}
        content={html}
        post={frontmatter}
        EmailBanner={EmailBanner}
        TableRow={TableRow}
        tableSpacing="120px 1fr 1fr 1fr 1fr;"
      >
        <Dog src={DogIcon} alt="Dog icon" />
      </BlogPostTemplate>
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD")
        description
        productsTable {
          productName
          isTopRated
          isBestValue
          bestOfBest
          bestForBudgets
          shopUrl
          tldr
          pros
          cons
          image {
            childImageSharp {
              gatsbyImageData(width: 200, quality: 100)
            }
          }
        }
        bottomTitle
        bannerImage {
          childImageSharp {
            gatsbyImageData(width: 1300, quality: 100)
          }
        }
      }
    }
  }
`
